<template>
  <Card class="mb-30">
    <div class="StatSessionChart">
      <div class="StatSessionChart__head">
        <h2>
          {{ $lng.__('lk_webmaster_main', `Distribution by ${this.geo}`) }}
        </h2>
        <!-- <template v-if="countriesQuantity != 0">
          <div @click="expand" class="StatSessionChart__expand">
            <SvgUse :width="22" :height="22" id="expand"></SvgUse>
          </div>
        </template> -->
      </div>

      <div v-if="expanded" class="StatSessionChart__content">
        <canvas
          :id="`wv-chart-${this.geo}`"
          class="StatSessionChart__canvas"
        ></canvas>
      </div>
      <div v-else-if="countriesQuantity == 0" class="StatSessionChart__content">
        <template v-if="this.geo == 'geo'">
          {{ $lng.__('lk_webmaster_main', `Not enough data to show this report`) }}
        </template>
        <template v-if="this.geo == 'region'">
          {{ $lng.__('lk_webmaster_main', `To display the chart, select a country in the filter`) }}
        </template>
      </div>
    </div>
  </Card>
</template>

<script>
  import { Chart } from 'chart.js';
  import chartColors from './chartColors.js';

  export default {
    name: 'StatAudienceGeoChart',
    emits: ['update:filters'],
    props: {
      geo: String,
      geoData: Array,
      filters: Array,
    },
    data() {
      return {
        expanded: false,
      };
    },
    computed: {
      countriesQuantity() {
        return collect(this.geoData).count();
      },
      countries() {
        let dataMap = {};
        let countriesData = collect(this.geoData)
          .pluck(`${this.geo}`)
          .all();

        countriesData.forEach(function(countriesItem) {
          if (dataMap[countriesItem] != undefined) {
            ++dataMap[countriesItem];
          } else {
            dataMap[countriesItem] = 1;
          }
        });
        
        return Object.entries(dataMap).sort(([,a],[,b]) => b-a).reduce((array, [key, value]) => ({ ...array, [key]: value }), {});
      },
      countriesCount() {
        let data = [];
        let countriesQuantity = this.countriesQuantity;

        for (let key in this.countries) {
          let percent = (this.countries[key] * 100) / countriesQuantity;
          data.push(percent.toFixed(2));
        }

        return data;
      },
      countriesNumber() {
        let data = [];
        let index = 0;

        for (let [key, value] of Object.entries(this.countries)) {
          data.push(`${key} - ${value} (${this.countriesCount[index]}%)`);
          index += 1;
        }

        return data;
      },
    },
    methods: {
      makeChart() {
        let that = this;
        
        window[`chart_${this.geo}`]
          ? window[`chart_${this.geo}`].destroy()
          : false;

        if (!document.getElementById(`wv-chart-${this.geo}`)) return;

        window[`chart_${this.geo}`] = new Chart(
          document.getElementById(`wv-chart-${this.geo}`),
          {
            type: 'doughnut',
            data: {
              labels: this.countriesNumber,
              datasets: [
                {
                  label: this.countriesNumber,
                  data: this.countriesCount,
                  backgroundColor: chartColors.reverse(),
                  hoverOffset: 4,
                },
              ],
            },

            options: {
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label;
                    return label[tooltipItem.index];
                  },
                },
              },
              legend: {
                position: 'left',
                align: 'start',
              },
              onClick: function(event, elements) {
                if (elements.length > 0 && that.geo === 'geo') {
                  var label = this.data.labels[elements[0]._index].slice(0, 2);
                  that.$emit('update:filters', {...that.filters, country: [{id:label, name:label}]});
                }
              },
              onResize: function() {
                if (window.matchMedia('(max-width: 767px)').matches) {
                  this.legend.position = 'top';
                  this.legend.align = 'center';
                } else {
                  this.legend.position = 'left';
                  this.legend.align = 'start';
                }
              },
            },
          }
        );
        // Responsive chart for mobile
        if (window.matchMedia('(max-width: 767px)').matches) {
          window[`chart_${this.geo}`].options.legend.position = 'top';
          window[`chart_${this.geo}`].options.legend.align = 'center';
          let height = (window[`chart_${this.geo}`].data.labels.length / 2) * 22 + window[`chart_${this.geo}`].height + 'px';
          window[`chart_${this.geo}`].canvas.parentNode.style.height = height;
          window[`chart_${this.geo}`].update();
        } else {
          window[`chart_${this.geo}`].options.legend.position = 'left';
          window[`chart_${this.geo}`].options.legend.align = 'start';
          window[`chart_${this.geo}`].update();
        }
      },
      expand() {
        if (this.expanded) {
          this.expanded = false;
        } else {
          this.expanded = true;
          setTimeout(this.makeChart, 200);
        }
      },
    },
    watch: {
      geoData: {
        deep: true,
        handler(val, oldVal) {
          if (collect(val).count() == 0) {
            this.expanded = true;
            this.expand();
          } else {
            this.expanded = false;
            this.expand();
          }
        },
      },
    },
    mounted() {
      //this.is_visible = true
      setTimeout(this.makeChart, 1000);
    },
  };
</script>

<style lang="scss">
  .StatSessionChart {
    &__head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
        padding-bottom: 20px;
      }
    }
    &__expand {
      opacity: 0.5;
      transition: 0.2s opacity ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
